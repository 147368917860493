<template>
  <div class="account-list">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
      <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
        <el-input v-model="form.query" placeholder="店铺名称/账号" style="width: 160px" clearable></el-input>
        <el-date-picker v-model="time" class="ml20" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          value-format='yyyy-MM-dd '>
        </el-date-picker>
      </div>
      <el-button type="primary" class="sure" @click="search">筛选</el-button>
      <el-button type="info" class="sure" @click="clear">清空</el-button>
      <PrevReclick>
        <el-button type="primary" class="sure" @click="exportAd">导出</el-button>
      </PrevReclick>
    </div>
     
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="shopName" label="店铺名称" width="140px"></el-table-column>
        <el-table-column prop="shopCountNumber" label="店铺账号" width="120px"></el-table-column>
        <el-table-column prop="id" label="广告主账号ID" width="100px"></el-table-column>
        <el-table-column width="180" prop="onlineDate" label="预计上线时间">
          <template slot-scope="scope">
             <span>{{formatTime(scope.row.onlineDate)}}</span>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="showType" label="套餐类型">
          <template slot-scope="scope">
            <span>{{showTypeList[scope.row.showType]}}</span>
          </template>
        </el-table-column>
        <el-table-column width="160" prop="showType" label="投放状态"><template slot-scope="scope">
            <el-select  v-model='scope.row.showState'  placeholder="请选择" @change="editShowType($event,scope.row)">
              <el-option v-for="(item,index) in options" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template></el-table-column>
        <el-table-column width="140" prop="verifyStatus" label="审核状态"><template slot-scope="scope">
            <span>{{verifyStatusList[scope.row.verifyStatus]}}</span>
          </template></el-table-column>
        <el-table-column width="120" prop="costAmount" label="累计花费"></el-table-column>
        <el-table-column width="120" prop="viewTimes" label="总观看量"></el-table-column>
        <el-table-column width="120" prop="couponCostAmount" label="优惠券领取成本"></el-table-column>
        <el-table-column width="140" prop="cluesCollectTimes" label="线索收集量"></el-table-column>
        <el-table-column prop="cluesCollectCostAmount" label="线索收集成本"></el-table-column>
        <el-table-column prop="pakage" label="操作" width="180" align="center" fixed="right">
          <template slot-scope="scope">
            <span class="account-oprate-but" @click="openAdDetail(scope.row)">{{aduitProcess(scope.row)}}</span>
            <el-divider direction="vertical">
            </el-divider>
            <span class="account-oprate-but" @click="lookDetail(scope.row)">预览</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <!-- 广告详情 -->
    <advert-detail ref='advertDetail' v-if="adDetailState" :id='id' :key='rushkey' />
  </div>
</template>
<script>
import {
  $sp_adverList,
  $agent_updateShowType,
} from '@/api/ad';
import { exportFileSync } from '@base/utils';
import advertDetail from './components/advertDeatil.vue';

const showTypeList = ['未选择', '10天600元', '30天2000元', '90天5000元', '180天9000元'];
const verifyStatusList = ['', '松鼠审核中', '制作完成', '松鼠审核通过', '松鼠审核未通过', '微信审核中', '微信审核通过', '微信审核未通过'];
// 1松鼠审核中(待制作)，2制作完成，3松鼠审核通过，4松鼠审核未通过，5微信审核中，6微信审核通过，7微信审核未通过
// 1松鼠审核中，2松鼠审核通过，3松鼠审核未通过，4微信审核中，5微信审核通过，6微信审核未通过
export default {
  data() {
    return {
    
      id: null,
      rushkey: '',
      adDetailState: false,
      verifyStatusList,
      showTypeList,
      form: {
        orderId: '',
        query: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      loading: false,
      agentList: [],
      shopList: [],
      options: [{ value: 0, label: '待投放' }, { value: 1, label: '投放中' },
        { value: 2, label: '已结束' }],
      payStatusList: [
        {
          value: 2,
          label: '已支付',
        },
        {
          value: 3,
          label: '支付后退款',
        },
      ],
    };
  },
  components: {
    advertDetail,
  },
  created() {
    this.getTableData({ pageSize: 10, pageCurrent: this.currentPage });
  },
  mounted() {

  },
  methods: {
    formatTime(time) {
      if (time) {
        return this.$formatDateByDate(new Date(time), 'yyyy-MM-dd HH:mm');
      } else {
        return '';
      }
    },
    editShowType(e, item) {
      $agent_updateShowType({ adId: item.id, showState: e }).then(() => {
        this.getTableData({ pageSize: 10, pageCurrent: this.currentPage });
      });
    },
    // 导出广告列表
    exportAd() {
      if ((!this.form.query) && !this.time) {
        this.$message.error('请输入店铺名称账号，或者日期');
        return;
      }
      let startTime = null; let endTime = null;
      if (this.time) {
        startTime = this.time[0] || null; endTime = this.time[1] || null;
      }
      exportFileSync(
        '/j/platform/t-wx-localad-ad/list/export',
        {
          query: this.form.query, startTime, endTime,
        },
        'get',
        'qrcode_download.zip',
      ).then(() => {
        // this.handleDownloadQrCodeLeftBtn();
      });
    },
    // 
    lookDetail(item) {
      this.$router.push({
        name: 'CreateAd',
        query: {
          adState: 4,
          id: item.id,
        },
      });
    },
    aduitProcess(item) {
      const state = item.verifyStatus;
      if (state === 1) {
        return '制作';
      } if (state === 2) {
        return '审核';
      } if (state === 4) {
        return '制作';
      } if (state === 5) {
        return '更改状态';
      } if (state === 6) {
        return '详情';
      }
      if (state === 7) {
        return '制作';
      } else {
        return '更改状态';
      }
    },
    // 广告详情
    openAdDetail(item) {
      const state = item.verifyStatus;
      if (item.verifyStatus === 6) {
        this.id = item.id;
        this.adDetailState = true;
        this.$nextTick(() => {
          this.$refs.advertDetail.advertDetailVisible = true;
        });
      } else {
        const stateList = ['', 1, 2, '', 1, 3, 4, 1];
        this.$router.push({
          name: 'CreateAd',
          query: {
            adState: stateList[state],
            id: item.id,
          },
        });
      }
    },
    // 广告详情
    // 筛选
    search() {
      this.currentPage = 1;
      let startTime = null; let endTime = null;
      if (this.time) {
        startTime = this.time[0] || null; endTime = this.time[1] || null;
      }
      this.getTableData({
        pageSize: 10, pageCurrent: this.currentPage, query: this.form.query, startTime, endTime,
      });
    },
    // 清空
    clear() {
      this.currentPage = 1;
      this.form.query = '';
      this.time = '';
      this.getTableData({ pageSize: 10, pageCurrent: this.currentPage });
    },
    // 请求列表数据
    getTableData(params = {}) {
      $sp_adverList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 翻页
    pageChange() {
      let startTime = null; let endTime = null;
      if (this.time) {
        startTime = this.time[0] || null; endTime = this.time[1] || null;
      }
      this.getTableData({
        pageSize: 10, pageCurrent: this.currentPage, query: this.form.query, startTime, endTime,
      });
    },
  },
};
</script>
<style lang="scss" >
.account-list {
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table__body-wrapper {
    min-height: 510px;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .account-oprate-but {
    cursor: pointer;
    color: #ef3f46;
  }
}
</style>

