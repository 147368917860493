<template>
  <div class="advert-detail-page">
    <el-dialog class="advert-detail-dialog" :visible="advertDetailVisible" width="960px" @close="close('advertDetailVisible')" title="广告详情">
      <el-button type="primary" size="default" @click="rushAdvert" style="float:right">更新数据</el-button>
      <ul class="advert-detail-type">
        <li v-for="item in dataClass" :key="item.id" :class="{ active: active == item.id }" @click="selectLi(item.id)">
          {{ item.name }}
        </li>

      </ul>
      <el-table :data="tableData" style="width: 100%;margin-top:30px;" border max-height="350px">
         <el-table-column prop="statisticsDate" label="日期">
         
         </el-table-column>
        <el-table-column prop="costAmount" label="花费(元)"></el-table-column>
        <el-table-column prop="viewTimes" label="观看量(次)"></el-table-column>
         <el-table-column prop="couponReceiveTimes" label="优惠券领取量(次)">
        </el-table-column>
        <el-table-column prop="couponCostAmount" label="优惠券领取成本(元)"></el-table-column>
        <el-table-column prop="cluesCollectTimes" label="线索搜集量(个)"></el-table-column>
        <el-table-column prop="cluesCollectCostAmount" label="线索搜集成本"></el-table-column>
      </el-table>
      <el-button type="primary" size="default" @click="addNewPhone" style="float:right;margin-top:30px">新增手机号</el-button>
      <p class="add-user-phone">新增用户手机号</p>
      <el-table :data="phoneData" style="width: 100%;margin-top:30px" border max-height="550px">
        <el-table-column prop="key" label="序号"></el-table-column>
        <el-table-column prop="createTime" label="时间"></el-table-column>
        <el-table-column prop="phone" label="电话号码"></el-table-column>
          <el-table-column prop="phone" label="操作" align="center">
            <template slot-scope="scope">
              <span @click="delTel(scope.row)" style="color:rgba(246, 61, 42, 1);cursor: pointer;">删除</span>
            </template>
          </el-table-column>
      </el-table>
       <!-- <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination> -->
    </el-dialog>
    <!-- 更新数据 -->
    <el-dialog class="advert-detail-dialog" :visible="rushDataVisible" width="440px" @close="close('rushDataVisible')" title="新增手机号">
      <el-input v-model="addPhones" placeholder="" size="normal" clearable type="number"></el-input>
      <footer class="add-footer">
        <PrevReclick >
        <el-button type="primary" size="default" @click="addphone">确定</el-button>
        </PrevReclick>
        <el-button size="default" @click="rushDataVisible=false">取消</el-button>
      </footer>
    </el-dialog>
    <el-dialog class="advert-detail-dialog" :visible="rushDayDetailVisible" width="960px" @close="close('rushDayDetailVisible')"
      title="更新每日数据">
      <el-table :data="tabelDayData" style="width: 100%;margin-top:30px" border max-height="350px">
        <el-table-column prop="costAmount" label="花费(元)">
          <template>
            <input v-model="dayForm.costAmount" placeholder="" size="normal" clearable class="vue-input-style"/>
          </template>
        </el-table-column>
        <el-table-column prop="viewTimes" label="观看量(次)">
          <template>
            <input v-model="dayForm.viewTimes" placeholder="" size="normal" clearable class="vue-input-style"/>
          </template>
        </el-table-column>
        
        <el-table-column prop="couponReceiveTimes" label="优惠券领取量(次)">
          <template>
            <input v-model="dayForm.couponReceiveTimes" placeholder="" size="normal" clearable class="vue-input-style"/>
          </template>
        </el-table-column>
        <el-table-column prop="couponCostAmount" label="优惠券领取成本(元)">
          <template>
            <input v-model="dayForm.couponCostAmount" placeholder="" size="normal" clearable class="vue-input-style"/>
          </template>
        </el-table-column>
        <el-table-column prop="cluesCollectTimes" label="线索搜集量(个)">
          <template>
            <input v-model="dayForm.cluesCollectTimes" placeholder="" size="normal" clearable class="vue-input-style"/>
          </template>
        </el-table-column>
        <el-table-column prop="cluesCollectCostAmount" label="线索搜集成本">
          <template>
            <input v-model="dayForm.cluesCollectCostAmount" placeholder="" size="normal" clearable class="vue-input-style"/>
          </template>
        </el-table-column>
      </el-table>
      <footer class="add-footer">
        <el-button type="primary" size="default" @click="daySure">确定</el-button>
        <el-button size="default" @click="rushDayDetailVisible=false">取消</el-button>
      </footer>
    </el-dialog>
  </div>
</template>

<script>
import {
  $sp_advderDetail, $sp_addPhone, $sp_addData, $sp_deleteTel, $sp_pageShopAdPhone,
} from '@/api/ad';

const dataClass = [
  { name: '累计数据', id: 0 },
  { name: '昨日数据', id: 1 },
  { name: '今日数据', id: 2 },
];


export default {
  components: {},
  data() {
    return {
      tableData: [],
      phoneData: [],
      tabelDayData: [{}],
      dataClass,
      advertDetailVisible: false,
      active: null,
      rushDataVisible: false,
      addPhones: '',
      rushDayDetailVisible: false,
      dayForm: {},
      totalPage: 1,
      currentPage: 1,
    };
  },
  computed: {},
  watch: {
    id(value) {
      this.advderDetail({ adId: value, type: 0 });
      this.pageShopAdPhone({ adId: this.id, pageNo: this.currentPage, pageSize: 100 });
    },
  },
  props: {
    id: {
      type: Number,
    },
  },
  created() { },
  mounted() {
    this.advderDetail({ adId: this.id, type: 0 });
    this.pageShopAdPhone({ adId: this.id, pageNo: this.currentPage, pageSize: 100 });
  },
  // 方法集合
  methods: {
    // 手机号码列表
    pageShopAdPhone(param) {
      $sp_pageShopAdPhone(param).then((res) => {
        this.totalPage = res.pages;
        this.phoneData = res.records;
        this.phoneData.forEach((item, key) => {
          item.key = key + 1;
        });
      });
    },
    pageChange() {
      this.pageShopAdPhone({ adId: this.adId, pageNo: this.currentPage, pageSize: 100 });
    },
    // 删除号码
    delTel(item) {
      this.$confirm('是否删除手机号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $sp_deleteTel({ adId: this.id, phoneNo: item.phone }).then(() => {
          this.$message({
            message: '删除成功',
            type: 'success',
          });
          this.pageShopAdPhone({ adId: this.id, pageNo: this.currentPage, pageSize: 100 });
        });
      }).catch(() => {   
      });
    },
    // 获取详情
    advderDetail(param) {
      $sp_advderDetail(param).then((res) => {
        this.tableData = [];
        this.tableData.push(res);
      });
    },
    // 更新广告数据
    rushAdvert() {
      this.rushDayDetailVisible = true;
    },
    close(type) {
      this[type] = false;
    },
    selectLi(num) {
      this.active = num;
      this.advderDetail({ adId: this.id, type: num });
    },
    // 更新数据
    addNewPhone() {
      this.rushDataVisible = true;
      this.addPhones = '';
    },
    // 更新数据确定
    daySure() {
      const numRegular = /^[0-9.]{1,20}$/;
      const numIntRegular = /^[0-9]{1,20}$/;
      // const numFloatRegular = /^[0-9]{0,20}$/;
      if (!numRegular.test(this.dayForm.costAmount)) {
        this.$message.error('请输入花费(元),数字且大于0');
        return;
      }
      if (!numIntRegular.test(this.dayForm.viewTimes)) {
        this.$message.error('请输入观看次数,整数且大于0');
        return;
      }
      if (!numIntRegular.test(this.dayForm.couponReceiveTimes)) {
        this.$message.error('请输入优惠券领取量,整数且大于0');
        return;
      }
      if (!numRegular.test(this.dayForm.couponCostAmount)) {
        this.$message.error('请输入优惠券成本,数字且大于0');
        return;
      }
      if (!numIntRegular.test(this.dayForm.cluesCollectTimes)) {
        this.$message.error('请输入线索收集量,整数且大于0');
        return;
      }
      if (!numRegular.test(this.dayForm.cluesCollectCostAmount)) {
        this.$message.error('请输入线索收集成本,数字且大于0');
        return;
      }
      const time = new Date();
      const day = this.$formatDateByDate(time, 'yyyy-MM-dd');
      $sp_addData({ ...this.dayForm, adId: this.id, createTimeStr: day }).then(() => {
        this.rushDayDetailVisible = false;
        this.advderDetail({ adId: this.id, type: 0 });
        this.pageShopAdPhone({ adId: this.id, pageNo: this.currentPage, pageSize: 100 });
      });
    },
    // 新增手机号
    addphone() {
      const phoneRegular = /^\d{11}$/;
      if (!phoneRegular.test(this.addPhones)) {
        this.$message.error('请输入正确电话号码');
        return;
      }
      $sp_addPhone({ phone: this.addPhones, id: this.id }).then(() => {
        this.rushDataVisible = false;
        this.pageShopAdPhone({ adId: this.id, pageNo: this.currentPage, pageSize: 100 });
      });
    },
  },
};
</script>
<style lang='scss' >
.advert-detail-page{
.advert-detail-dialog {
   input:focus-visible{
    outline: none;
   }
  .vue-input-style{
    width: 120px;
    height: 30px;
    border: 1px solid #6666;
    border-radius: 5px;
  }
   .el-table__body-wrapper{
    min-height: 110px;
  }
  .el-table {
    min-height: 180px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .el-dialog__header {
    background: #f8f7f7;
    padding-left: 35px !important;
  }
  .el-dialog__body {
    padding: 30px 35px;
  }
  .advert-detail-type {
    display: flex;
    .active {
      border: 1px solid rgba(246, 61, 42, 0.6);
      color: rgba(246, 61, 42, 1);
    }
    li {
      color: #666666;
      line-height: 32px;
      width: 135px;
      border: 1px solid rgba(102, 102, 102, 0.3);
      border-radius: 5px;
      margin-right: 20px;
      text-align: center;
      cursor: pointer;
    }
  }
  .add-user-phone {
    color: #666666;
    margin-top: 30px;
  }
  .add-footer {
    margin: 20px auto 0 auto;
    width: 50%;
    display: flex;
    justify-content: space-between;
  }
}
}
</style>
